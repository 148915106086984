<template>
    <section>
        <div class="container">
            <TitleBar title="Müşteri / Tedarikçi Kayıtları" :modi="modi" />

            <b-field class="columns box" label="Filtre Alanları" grouped group-multiline>
                <b-field class="column is-2" label="ID">
                    <b-input v-model="customers.filters.id"></b-input>
                </b-field>
                <b-field class="column is-3" label="Müşteri/Tedarikçi Sınıfı">
                    <b-select placeholder="Seçiniz.." v-model="customers.filters.class" expanded>
                        <option value="Aday">Aday</option>
                        <option value="Tedarikçi">Tedarikçi</option>
                        <option value="Müşteri">Müşteri</option>
                        <option value="Herikisi">Herikisi</option>
                        <option value="Diğer">Diğer</option>
                    </b-select>
                </b-field>
                <b-field class="column is-7" label="Unvan">
                    <b-input v-model="customers.filters.name"></b-input>
                </b-field>
                <b-field class="column is-4" label="Ana Grup">
                    <b-input v-model="customers.filters.maingroup"></b-input>
                </b-field>
                <b-field class="column is-4" label="Vergi Dairesi">
                    <b-input v-model="customers.filters.taxadm"></b-input>
                </b-field>
                <b-field class="column is-4" label="Müşteri Temsilcisi">
                    <UserAutofill v-model="customers.filters.cusrep" />
                </b-field>
            </b-field>

            <b-field class="columns box mt-2" label="Müşteri/Tedarikçi Tablosu" grouped group-multiline>
                <b-field class="column is-12" grouped group-multiline>
                    <b-button class="column" type="is-info is-light" icon-left="magnify" label="Ara" @click="customers_read" />
                    <b-button class="column ml-1" type="is-success is-light" icon-left="plus" label="Yeni" @click="customers_new" />
                    <b-button class="column ml-1" type="is-success is-light" icon-left="content-copy" label="Ref. Al" @click="customers_ref" disabled />
                    <b-button class="column ml-1" type="is-info is-light" icon-left="eye" label="Görüntüle" :disabled="!customers.selected.id" @click="customers_show" />
                    <b-button class="column ml-1" type="is-warning is-light" icon-left="pencil" label="Düzenle" :disabled="!customers.selected.id" @click="customers_edit" />
                    <b-button class="column ml-1" type="is-danger is-light" icon-left="delete" label="Sil" :disabled="!customers.selected.id" @click="customers_delete" />
                    <b-button class="column ml-1" type="is-link is-light" icon-left="printer" label="Yazdır" @click="customers_print" disabled />
                    <b-button class="column ml-1" type="is-link is-light" icon-left="import" label="Içe Aktar" disabled />
                    <b-button class="column ml-1" type="is-link is-light" icon-left="export" label="Dışa Aktar" @click="customers_export" disabled />
                </b-field>

                <b-table
                    class="column is-12"
                    :total="customers.total"
                    :data="customers.data"
                    :mobile-cards="false"
                    :current-page.sync="customers.page"
                    :selected.sync="customers.selected"
                    :sort-multiple-data.sync="customers.sorts"
                    :loading="$apollo.queries.readCustomers.loading"
                    @dblclick="customers_show"
                    @sort="customers_sort"
                    @sorting-priority-removed="customers_nosort"
                    backend-pagination
                    backend-sorting
                    sort-multiple
                    striped
                    hoverable
                    narrowed
                    paginated
                    pagination-simple
                >
                    <template slot="empty">
                        <div class="is-12 has-text-centered">
                            Müşteri / Tedarikçi kaydı bulunamadı.
                        </div>
                    </template>

                    <b-table-column field="id" label="ID" v-slot="props" sortable>{{ props.row.id.slice(-4) }}</b-table-column>
                    <b-table-column field="class" label="Müşteri Sınıfı" v-slot="props" sortable>{{ props.row.class }}</b-table-column>
                    <b-table-column field="firmrep" label="Firma Temsilcisi" v-slot="props" sortable>{{ props.row.firmrep }}</b-table-column>
                    <b-table-column field="name" label="Unvan" v-slot="props" sortable>{{ props.row.name }}</b-table-column>
                    <b-table-column field="taxadm" label="Vergi Dairesi" v-slot="props" sortable>{{ props.row.taxadm }}</b-table-column>
                    <b-table-column field="cusrep.name" label="Müşteri Temsilcisi" v-slot="props">{{ props.row.cusrep.name }}</b-table-column>
                </b-table>
            </b-field>
        </div>
    </section>
</template>

<script>
import gql from "graphql-tag";
import TitleBar from "@/components/TitleBar.vue";
import UserAutofill from "@/components/UserAutofill.vue";

export default {
    name: "CustomerTable",
    components: {
        TitleBar,
        UserAutofill
    },
    data: () => ({
        modi: "",
        customers: {
            page: 1,
            take: 20,
            total: 9999,
            data: [],
            sorts: [],
            filters: {
                cusrep: {}
            },
            selected: {}
        }
    }),
    mounted() {
        this.customers_read();
    },
    methods: {
        customers_read() {
            this.$apollo.queries.readCustomers.start();
            this.$apollo.queries.readCustomers.refetch();
        },
        customers_new() {
            this.$router.push({ path: "/musteri/ekle" });
        },
        customers_ref() {
            this.$router.push({
                path: "/musteri/ekle",
                query: { id: this.customers.selected.id, modi: "ref" }
            });
        },
        customers_show() {
            this.$router.push({
                path: "/musteri/detay",
                query: { id: this.customers.selected.id, modi: "show" }
            });
        },
        customers_edit() {
            this.$router.push({
                path: "/musteri/detay",
                query: { id: this.customers.selected.id, modi: "edit" }
            });
        },
        customers_delete() {},
        customers_print() {},
        customers_export() {},
        customers_sort(field, order) {
            this.customers_nosort(field);
            this.customers.sorts.push({ field, order });
        },
        customers_nosort(field) {
            this.customers.sorts.forEach((e, i, a) => {
                if (e.field == field) a.splice(i, 1);
            });
        }
    },
    apollo: {
        readCustomers: {
            query: gql`
                query readCustomers($config: String) {
                    readCustomers(config: $config) {
                        id
                        name
                        class
                        cusrep {
                            id
                            name
                        }
                        firmrep
                        taxadm
                        note
                    }
                }
            `,
            variables() {
                var sorts = [];
                this.customers.sorts.forEach((e) => sorts.push({ column: e.field, order: e.order }));
                for (var filter in this.customers.filters) if (!this.customers.filters[filter]) delete this.customers.filters[filter];

                return {
                    config: JSON.stringify({
                        sorts,
                        take: this.customers.take,
                        filters: this.customers.filters,
                        skip: (this.customers.page - 1) * this.customers.take
                    })
                };
            },
            update(data) {
                this.customers.data = data.readCustomers;
            }
        }
    }
};
</script>
